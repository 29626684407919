import { render, staticRenderFns } from "./DescriptionConfig.vue?vue&type=template&id=5dd903f7&scoped=true"
import script from "./DescriptionConfig.vue?vue&type=script&lang=js"
export * from "./DescriptionConfig.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5dd903f7",
  null
  
)

export default component.exports